<template>
    <div>
        <div>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        @click="tabularMode = 'data-table'"
                        v-on="on"
                    >
                        <v-icon  :color="tabularMode == 'data-table' ? 'green darken-1' : ''">mdi mdi-table</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('message.simpleList') }}</span>
            </v-tooltip>
            <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                    <v-btn
                        icon
                        @click="tabularMode = 'bar-chart'"
                        v-on="on"
                    >
                        <v-icon :color="tabularMode == 'bar-chart' ? 'green darken-1' : ''">mdi mdi-chart-bar</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('message.barChart') }}</span>
            </v-tooltip>
            <ExportTableJson
                    :export-conditions="{}"
                    :export-data="groupedReportData"
                    :export-fields="tableTitles"
                    :export-source="'marketing-report'"
                    class="float-right"
                    style="padding-top: 0px !important;"
                    v-if="tabularMode == 'data-table'"
            />
            <v-tooltip bottom v-if="tabularMode == 'bar-chart'">
                <template v-slot:activator="{ on }">
                    <v-btn
                        class="float-right px-2"
                        v-on="on"
                        @click="printBarChart()"
                    >
                        <v-icon>print</v-icon>
                    </v-btn>
                </template>
                <span>{{ $t('message.printChart') }}</span>
            </v-tooltip>
        </div>
        <template v-if="tableOptions.yearOnYear == 0 && tabularMode == 'data-table'">
            <v-slide-x-reverse-transition>
                <v-data-table
                        :headers="headers"
                        :height="tabular.tableHeight"
                        :group-by="tableOptions.groupBy != null ? 'group_position' : null"
                        :items="groupedReportData"
                        :items-per-page="-1"
                        :multi-sort="true"
                        :custom-sort="customSort"
                        calculate-widths
                        dense
                        disable-pagination
                        fixed-header
                        hide-default-header
                        hide-default-footer
                >
                    <template v-slot:top>
                        <div class="d-flex flex-row justify-space-between">
                            <span class="font-weight-bold">{{ $t('message.date') + ": " + formatDate(tableTitles.currentDate) }}</span>
                            <span class="font-weight-bold" v-if="tableTitles.dateRange != null">{{ tableTitles.dateRange }}</span>
                        </div>
                        <div class="d-flex flex-row justify-space-between">
                            <div class="d-flex flex-row">
                                <span class="font-weight-bold font-sm">
                                    {{ $t('message.resultsBy') + ": " + tableTitles.resultBy }}
                                </span>
                                <span class="font-weight-bold font-sm ml-3" v-if="tableTitles.groupBy">
                                    {{ $t('message.basedOn') + ": " + tableTitles.groupBy }}
                                </span>
                            </div>
                            <span class="font-weight-bold font-sm">{{ tableTitles.value }}</span>
                        </div>
                        <div class="d-flex flex-row justify-space-between">
                            <span class="font-weight-bold font-sm">{{ $t('message.salesType') + ": " + tableTitles.salesType }}</span>
                            <span class="font-weight-bold font-sm">{{ '$' + formatThisNumber(tableTitles.total, '0,0') }}</span>
                        </div>
                        <div class="black" style="height: 2px !important;"></div>
                    </template>
                    <template v-slot:item.index="{ item }">
                        <div class="text-center">{{ item.index }}</div>
                    </template>
                    <template v-slot:item.result_text="{ item }">
                        <div class="detail-link" @click="openDetails(item.result_id, 'group_by_id' in item ? item.group_by_id : null )">{{ item.result_text }}</div>
                    </template>
                    <template v-slot:item.result_value="{ item }">
                        <div class="detail-link d-flex flex-row align-center justify-end" @click="openDetails(item.result_id, 'group_by_id' in item ? item.group_by_id : null )">
                            <div>{{ formatThisNumber(item.result_value,'0,0') }}</div>
                            <div class="ml-3" style="width: 40px !important" v-if="'group_by_id' in item">{{ formatThisNumber(getPercent(item.result_value,item.group_position),'0.0') + '%' }}</div>
                            <div class="ml-3" style="width: 40px !important" v-else>{{ formatThisNumber(getPercent(item.result_value,tableTitles.total),'0.0') + '%' }}</div>
                        </div>
                    </template>
                    <template v-slot:group.header="{ group, items }">
                        <td colspan="3" class="font-weight-bold px-0 pt-5 white">
                            <div class="d-flex flex-row justify-space-between pb-2">
                                <div class="detail-link" @click="openGroupDetails( items[0]['group_by_id'] )">{{ items[0]['group_by'] ? items[0]['group_by'] : $t('message.na') }}</div>
                                <div class="text-right">{{ formatThisNumber(groupTotal(items),'0,0') }}</div>
                            </div>
                            <div class="grey" style="height: 1px !important;"></div>
                        </td>
                    </template>
                </v-data-table>
            </v-slide-x-reverse-transition>
        </template>
        <template v-if="tableOptions.yearOnYear == 1 && tabularMode == 'data-table'">
            <v-data-table
                    :headers="yearOnYearHeaders"
                    :height="tabular.tableHeight"
                    :group-by="tableOptions.groupBy != null ? 'group_position' : null"
                    :items="groupedReportData"
                    :items-per-page="itemsPerPageYearOnYear"
                    :sort-by.sync="yearOnYearSortBy"
                    :custom-sort="yearOnYearCustomSort"
                    calculate-widths
                    dense
                    fixed-header
                    hide-default-footer
                    sort-desc
            >
                <template v-slot:top>
                    <div class="d-flex flex-row justify-space-between">
                        <span class="font-weight-bold">{{ $t('message.date') + ": " + formatDate(tableTitles.currentDate) }}</span>
                        <span class="font-weight-bold" v-if="tableTitles.dateRange != null">{{ tableTitles.dateRange }}</span>
                    </div>
                    <div class="d-flex flex-row justify-space-between">
                        <div class="d-flex flex-row">
                            <span class="font-weight-bold font-sm">
                                {{ $t('message.resultsBy') + ": " + tableTitles.resultBy }}
                            </span>
                            <span class="font-weight-bold font-sm ml-3" v-if="tableTitles.groupBy">
                                {{ $t('message.basedOn') + ": " + tableTitles.groupBy }}
                            </span>
                        </div>
                        <span class="font-weight-bold font-sm">{{ tableTitles.value }}</span>
                    </div>
                    <div class="d-flex flex-row justify-space-between">
                        <span class="font-weight-bold font-sm">{{ $t('message.salesType') + ": " + tableTitles.salesType }}</span>
                        <span class="font-weight-bold font-sm">{{ '$' + formatThisNumber(tableTitles.total, '0,0') }}</span>
                    </div>
                    <div class="black" style="height: 2px !important;"></div>
                </template>
                <template v-slot:body.prepend="{ items }">
                    <tr>
                        <td></td>
                        <td class="font-weight-bold text-right">{{ $t('message.yearTotal') }}</td>
                        <td class="font-weight-bold text-right" v-for="year in years">{{ formatThisNumber(yearOnYearTotals[year.indexValue],'0,0') }}</td>
                    </tr>
                </template>
                <template v-slot:item.index="{ item }">
                    <div class="text-center">{{ item.index }}</div>
                </template>
                <template v-slot:item.result_text="{ item }">
                    <div class="detail-link" @click="openDetails( item.result_id, 'group_by_id' in item ? item.group_by_id : null )">{{ item.result_text }}</div>
                </template>
                <template v-for="year in years" v-slot:[year.indexName]="{ item }">
                    <div class="detail-link pr-3 d-flex flex-row align-center justify-end" @click="openYearDetails( item.result_id, 'group_by_id' in item ? item.group_by_id : null, year.indexValue )">
                        <div>{{ formatThisNumber(item[year.indexValue],'0,0') }}</div>
                        <div class="ml-3 text-right" style="width: 40px !important">{{ formatThisNumber(getPercent(item[year.indexValue],yearOnYearTotals[year.indexValue]),'0.0') + '%' }}</div>
                    </div>
                </template>
                <template v-slot:group.header="{ group, items }">
                    <td colspan="2" class="font-weight-bold px-0 pt-5 white">
                        <div class="d-flex flex-row justify-space-between">
                            <div class="detail-link text-no-wrap " @click="openGroupDetails( items[0]['group_by_id'] ) ">{{ items[0]['group_by'] ? items[0]['group_by'] : $t('message.na') }}</div>
                        </div>
                        <div class="grey" style="height: 1px !important;"></div>
                    </td>
                    <td v-for="year in years" class="pt-5 px-0 white">
                        <div class="font-weight-bold text-right pr-4">{{ formatThisNumber(yearOnYearGroupTotal(items, year),'0,0') }}</div>
                        <div class="grey" style="height: 1px !important;"></div>
                    </td>
                </template>
            </v-data-table>
        </template>
        <v-slide-x-reverse-transition>
            <div v-if="tabularMode == 'bar-chart'" class="d-flex flex-column justify-center">
                <vue-html2pdf
                    :show-layout="true"
                    :filename="'Marketing Report'"
                    :float-layout="false"
                    :enable-download="false"
                    :html-to-pdf-options="htmlToPdfOptions"
                    :manual-pagination="true"
                    :pdf-quality="2"
                    :preview-modal="false"
                    pdf-format="a4"
                    pdf-content-width="100%"
                    pdf-orientation="landscape"
                    ref="html2Pdf"
                    @beforeDownload="beforeDownload($event)"
                >
                    <section slot="pdf-content">
                        <v-app>
                            <div class="d-flex flex-row justify-space-between">
                                <span class="font-weight-bold">{{ $t('message.date') + ": " + formatDate(tableTitles.currentDate) }}</span>
                                <span class="font-weight-bold" v-if="tableTitles.dateRange != null">{{ tableTitles.dateRange }}</span>
                            </div>
                            <div class="d-flex flex-row justify-space-between">
                                <div class="d-flex flex-row">
                                    <span class="font-weight-bold font-sm">
                                        {{ $t('message.resultsBy') + ": " + tableTitles.resultBy }}
                                    </span>
                                    <span class="font-weight-bold font-sm ml-3" v-if="tableTitles.groupBy">
                                        {{ $t('message.basedOn') + ": " + tableTitles.groupBy }}
                                    </span>
                                </div>
                                <span class="font-weight-bold font-sm">{{ tableTitles.value }}</span>
                            </div>
                            <div class="d-flex flex-row justify-space-between">
                                <span class="font-weight-bold font-sm">{{ $t('message.salesType') + ": " + tableTitles.salesType }}</span>
                                <span class="font-weight-bold font-sm">{{ '$' + formatThisNumber(tableTitles.total, '0,0') }}</span>
                            </div>
                            <div class="black" style="height: 2px !important;"></div>
                            <div id="chart_container" class="d-flex overflow-y-auto flex-column overflow-x-auto" :style="'height: '+ tabular.barChartHeight + 'px'">
                                <template v-if="tableOptions.yearOnYear == 1 && tableOptions.groupBy != null">
                                    <template v-for="(group, groupIndex) in reportBarData">
                                        <template v-if="Array.isArray(group)">
                                            <v-row no-gutters>
                                                <v-col class="text-center">
                                                    <ECharts
                                                        :options="getReportBarOptions({data: group, groupTitle: groupIndex})"
                                                        :style="cssVars" @rendered="rendered()"
                                                        autoresize
                                                        @click="params => openBarDetails(params)"
                                                    />
                                                </v-col>
                                                <div class="html2pdf__page-break" style="height: 0px !important;"></div>
                                            </v-row>
                                        </template>
                                        <template v-else>
                                            <template v-for="subGroup in group">
                                                <v-row no-gutters>
                                                    <v-col class="text-center">
                                                        <ECharts
                                                            :options="getReportBarOptions({data: subGroup, groupTitle: groupIndex})"
                                                            :style="cssVars" @rendered="rendered()"
                                                            autoresize
                                                            @click="params => openBarDetails(params)"
                                                        />
                                                    </v-col>
                                                    <div class="html2pdf__page-break" style="height: 0px !important;"></div>
                                                </v-row>
                                            </template>
                                        </template>
                                    </template>
<!--                                    <v-row no-gutters class="justify-center" :key="yearIndex">-->
<!--                                        <template v-for="(groupData, groupIndex, index) in yearData">-->
<!--                                            <v-col class="text-center col-6" :key="groupIndex" :style="'height: '+ (tabular.barChartHeight/2) + 'px'">-->
<!--                                                <ECharts-->
<!--                                                    :options="getReportBarOptions({year: yearIndex, groupIndex: groupIndex, groupData: groupData})"-->
<!--                                                    :style="cssVars" @rendered="rendered()"-->
<!--                                                    autoresize-->
<!--                                                    @click="params => openBarDetails(params)"-->
<!--                                                ></ECharts>-->
<!--                                            </v-col>-->
<!--                                            <div class="html2pdf__page-break" v-if="(index + 1) % 4 == 0 && index > 0" style="height: 0px !important;"></div>-->
<!--                                            <div class="html2pdf__page-break" v-if="(index + 1) % 4 != 0 && (index + 1) == Object.keys(yearData).length" style="height: 0px !important;"></div>-->
<!--                                        </template>-->
<!--                                    </v-row>-->
                                </template>
                                <template v-if="tableOptions.yearOnYear == 1 && tableOptions.groupBy == null">
                                    <template v-for="subBarData in reportBarData" >
                                        <v-row no-gutters>
                                            <v-col class="text-center">
                                                <ECharts
                                                    :options="getReportBarOptions(subBarData)"
                                                    :style="cssVars" @rendered="rendered()"
                                                    autoresize
                                                    @click="params => openBarDetails(params)"
                                                />
                                            </v-col>
                                            <div class="html2pdf__page-break" style="height: 0px !important;"></div>
                                        </v-row>
                                    </template>
<!--                                    <v-row no-gutters class="d-flex flex-column" :key="index" >-->
<!--                                        <v-col class="text-center">-->
<!--                                            <ECharts-->
<!--                                                :options="getReportBarOptions(data)"-->
<!--                                                :style="index == 0 ? cssVarsFirst : cssVars" @rendered="rendered()"-->
<!--                                                autoresize-->
<!--                                                @click="params => openBarDetails(params)"-->
<!--                                            ></ECharts>-->
<!--                                        </v-col>-->
<!--                                    </v-row>-->
<!--                                    <div class="html2pdf__page-break" style="height: 0px !important;"></div>-->
                                </template>
                                <template v-if="tableOptions.yearOnYear == 0 && tableOptions.groupBy != null">
                                    <template v-for="subBarData in reportBarData" >
                                        <v-row no-gutters>
                                            <v-col class="text-center">
                                                <ECharts
                                                    :options="getReportBarOptions({data: subBarData[Object.keys(subBarData)[0]], groupTitle: Object.keys(subBarData)[0]})"
                                                    :style="cssVars" @rendered="rendered()"
                                                    autoresize
                                                    @click="params => openBarDetails(params)"
                                                />
                                            </v-col>
                                            <div class="html2pdf__page-break" style="height: 0px !important;"></div>
                                        </v-row>
                                    </template>
                                </template>
                                <template v-if="tableOptions.yearOnYear == 0 && tableOptions.groupBy == null">
                                    <template v-if="Array.isArray(reportBarData)">
                                        <template v-for="subBarData in reportBarData">
                                            <v-row no-gutters class="d-flex flex-column">
                                                <v-col class="text-center">
                                                    <ECharts
                                                        :options="getReportBarOptions(subBarData)"
                                                        :style="cssVars" @rendered="rendered()"
                                                        autoresize
                                                        @click="params => openBarDetails(params)"
                                                    />
                                                </v-col>
                                                <div class="html2pdf__page-break" style="height: 0px !important;"></div>
                                            </v-row>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <v-row no-gutters>
                                            <v-col class="text-center">
                                                <ECharts
                                                    :options="getReportBarOptions(reportBarData)"
                                                    :style="cssVars" @rendered="rendered()"
                                                    autoresize
                                                    @click="params => openBarDetails(params)"
                                                />
                                            </v-col>
                                            <div class="html2pdf__page-break" style="height: 0px !important;"></div>
                                        </v-row>
                                    </template>
                                </template>
                            </div>
                        </v-app>
                    </section>
                </vue-html2pdf>
            </div>
        </v-slide-x-reverse-transition>
    </div>
</template>

<script>
// import reportDataJson from "../../../assets/marketingReport.json";
import {formatDate, log, numberFormat, getPercent} from "Helpers/helpers";
import "echarts/lib/chart/bar";
import "echarts/lib/component/legend";
import "echarts/lib/component/legendScroll";
import "echarts/lib/component/title";
import 'echarts/lib/component/tooltip'
import ECharts from "vue-echarts";
import VueHtml2pdf from 'vue-html2pdf';
// import {api} from "Api";
import {mapFields} from "vuex-map-fields";
import Please from 'pleasejs';
// import _ from 'lodash';

const LoadingComponent = () => import("Components/Appic/LoadingComponent");
const ExportTableJson = () => import("Components/Appic/ExportTableJson");

export default {
    name: "ListingTabularV4",
    props: ['reportData','reportHash','tableOptions', 'tableTitles'],
    components: { ExportTableJson, LoadingComponent, ECharts, VueHtml2pdf },
    data() {
        return {
            htmlToPdfOptions: {
                image: {
                    type: 'jpeg',
                    quality: 0.95
                },
                html2canvas: {
                    scale: 1.5
                }
            },
            itemsPerPageYearOnYear: -1,
            tabular: {
                multipleTableHeight: '500',
                barChartHeight: '500',
                tableHeight: '500',
            },
            tabularMode: 'data-table',
            yearOnYearSortBy: null
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        cssVarsFirst () {
            let width = ' 100%'
            let height = ' 95%'
            let paddingTop = ' 10px'
            if(this.tableOptions.yearOnYear == 1 && this.tableOptions.groupBy == null){
                height = ' ' + (this.tabular.barChartHeight - 25) + 'px'
            }
            if(Array.isArray(this.reportBarData)){
                height = ' ' + this.tabular.barChartHeight + 'px'
            }
            return {
                '--width': width,
                '--height': height,
                '--padding-top': paddingTop,
            }
        },
        cssVars () {
            let width = ' 100%'
            let height = ' 95%'
            let paddingTop = ' 10px'
            if(this.tableOptions.yearOnYear == 1 && this.tableOptions.groupBy == null){
                height = ' ' + this.tabular.barChartHeight + 'px'
            }
            if(this.tableOptions.yearOnYear == 1 && this.tableOptions.groupBy != null){
                height = ' ' + this.tabular.barChartHeight + 'px'
            }
            if(Array.isArray(this.reportBarData)){
                height = ' ' + this.tabular.barChartHeight + 'px'
            }
            return {
                '--width': width,
                '--height': height,
                '--padding-top': paddingTop,
            }
        },
        groupedReportData () {
            let data = []
            if(this.tableOptions.groupBy != null){
                if(this.tableOptions.yearOnYear == 1){
                    data = this.reportData
                    let totals = {}
                    this.reportData.forEach(d => {
                        let check = Object.keys(totals).filter( (x) => {
                            return x == d.group_by
                        })
                        if(check.length == 0){
                            totals[d.group_by] = {}
                        }
                        this.years.forEach(y => {
                            if (Object.keys(totals[d.group_by]).filter(x => x.includes(y.indexValue)).length == 0) {
                                totals[d.group_by][y.indexValue] = 0
                            }
                            totals[d.group_by][y.indexValue] += d[y.indexValue]
                        })
                    })
                    data.forEach((d, idx) => {
                        let groupPosition = 0
                        this.years.forEach(y => {
                            groupPosition += totals[d.group_by][y.indexValue]
                        })
                        data[idx]['group_position'] = groupPosition
                    })
                } else {
                    let totals = {}
                    this.reportData.forEach(d => {
                        if (d.group_by in totals) {
                            totals[d.group_by] += d.result_value
                        } else {
                            totals[d.group_by] = d.result_value
                        }
                    })
                    this.reportData.forEach(d => {
                        data.push({
                            result_id: d.result_id,
                            result_text: d.result_text,
                            result_value: d.result_value,
                            group_by_id: d.group_by_id,
                            group_by: d.group_by,
                            group_position: totals[d.group_by],
                        })
                    })
                }
            } else {
                data = this.reportData
            }
            return data
        },
        headers () {
            return [
                {
                    id: 1,
                    text: '#',
                    value: 'index',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left width-1-pct',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 2,
                    text: this.$t('message.actions'),
                    value: 'result_text',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-left',
                    searchable: false,
                    sortable: true
                },
                {
                    id: 3,
                    text: this.$t('message.value'),
                    value: 'result_value',
                    class: 'light-green lighten-3 py-0 pr-0 pl-1 font-sm text-right',
                    searchable: false,
                    sortable: true
                },
            ]
        },
        reportBarData () {
            let data = []
            if(this.tableOptions.groupBy != null){
                if(this.tableOptions.yearOnYear == 1){
                    data = {}
                    let tempDataObj = {}
                    if (this.reportData) {
                        let tempData =  this.reportData
                        tempData.sort((a, b) => {
                            if (a.group_position > b.group_position) return -1
                            if (a.group_position < b.group_position) return 1
                            return 0
                        })
                        tempData.forEach(d => {
                            if(Object.keys(tempDataObj).includes(d.group_by) == false) {
                                tempDataObj[d.group_by] = [d]
                            } else {
                                tempDataObj[d.group_by].push(d)
                            }
                        })
                        for(const key in tempDataObj) {
                            if (tempDataObj[key].length > 10) {
                                data[key] = {}
                                let tempDataArray = []
                                let size = 10

                                tempDataObj[key].sort((a,b)=>{
                                    if(a[this.tableTitles.sortColumn] > b[this.tableTitles.sortColumn]) return -1
                                    if(a[this.tableTitles.sortColumn] < b[this.tableTitles.sortColumn]) return 1
                                    return 0
                                })

                                for (let i = 0; i < tempDataObj[key].length; i += size) {
                                    tempDataArray.push(tempDataObj[key].slice(i, i + size))
                                }
                                for (let i = 0; i < tempDataArray.length; i++) {
                                    let firstRow = []
                                    firstRow.push('') //temp
                                    tempDataArray[i].forEach(d => {
                                        firstRow.push(d.result_text)
                                    })
                                    data[key]['key' + i] = [firstRow]
                                    this.years.forEach(y => {
                                        let tempSingleData = []
                                        tempSingleData.push(y.indexValue)
                                        tempDataArray[i].forEach(d => {
                                            tempSingleData.push(d[y.indexValue])
                                        })
                                        data[key]['key' + i].push(tempSingleData)
                                    })
                                }
                            } else {
                                tempDataObj[key].sort((a,b)=>{
                                    if(a[this.tableTitles.sortColumn] > b[this.tableTitles.sortColumn]) return -1
                                    if(a[this.tableTitles.sortColumn] < b[this.tableTitles.sortColumn]) return 1
                                    return 0
                                })
                                let firstRow = []
                                firstRow.push('') //temp
                                tempDataObj[key].forEach(d => {
                                    firstRow.push(d.result_text)
                                })
                                data[key] = [firstRow]
                                this.years.forEach(y => {
                                    let tempSingleData = []
                                    tempSingleData.push(y.indexValue)
                                    tempDataObj[key].forEach(d => {
                                        tempSingleData.push(d[y.indexValue])
                                    })
                                    data[key].push(tempSingleData)
                                })
                            }
                        }
                    }
                    // if (this.reportData) {
                    //     let tempData =  this.reportData
                    //     tempData.sort((a, b) => {
                    //         if (a.group_position > b.group_position) return -1
                    //         if (a.group_position < b.group_position) return 1
                    //         return 0
                    //     })
                    //     tempData.forEach(d => {
                    //         if(Object.keys(tempDataObj).includes(d.group_by) == false) {
                    //             tempDataObj[d.group_by] = [d]
                    //         } else {
                    //             tempDataObj[d.group_by].push(d)
                    //         }
                    //     })
                    //     let firstRow = []
                    //     firstRow.push('year') //temp
                    //     this.years.forEach(y => {
                    //         firstRow.push(y.indexValue)
                    //     })
                    //     for(const key in tempDataObj){
                    //         if (tempDataObj[key].length > 10) {
                    //             data[key] = {}
                    //             let tempDataArray = []
                    //             let size = 10
                    //
                    //             tempDataObj[key].sort((a,b)=>{
                    //                 if(a[this.tableTitles.sortColumn] > b[this.tableTitles.sortColumn]) return -1
                    //                 if(a[this.tableTitles.sortColumn] < b[this.tableTitles.sortColumn]) return 1
                    //                 return 0
                    //             })
                    //
                    //             for (let i = 0; i < tempDataObj[key].length; i += size) {
                    //                 tempDataArray.push(tempDataObj[key].slice(i, i + size))
                    //             }
                    //             for (let i = 0; i < tempDataArray.length; i++) {
                    //                 data[key]['key' + i] = [firstRow]
                    //                 tempDataArray[i].forEach(d => {
                    //                     let tempSingleData = []
                    //                     tempSingleData.push(d.result_text)
                    //                     this.years.forEach(y => {
                    //                         tempSingleData.push(d[y.indexValue])
                    //                     })
                    //                     data[key]['key' + i].push(tempSingleData)
                    //                 })
                    //             }
                    //         } else {
                    //             data[key] = []
                    //             data[key].push(firstRow)
                    //             tempDataObj[key].forEach(d => {
                    //                 let tempSingleData = []
                    //                 tempSingleData.push(d.result_text)
                    //                 this.years.forEach(y => {
                    //                     tempSingleData.push(d[y.indexValue])
                    //                 })
                    //                 data[key].push(tempSingleData)
                    //             })
                    //             data[key].sort((a,b)=>{
                    //                 if(a[1] > b[1]) return -1
                    //                 if(a[1] < b[1]) return 1
                    //                 return 0
                    //             })
                    //         }
                    //     }
                    // }
                } else {
                    if (this.reportData) {
                        let tempData = []
                        this.reportData.forEach(d => {
                            let idxGroupBy = tempData.findIndex((el) => {
                                return Object.keys(el)[0] == d.group_by
                            })
                            if (idxGroupBy >= 0) {
                                let idxResultText = tempData[idxGroupBy][d.group_by].findIndex(el => el.name == d.result_text)
                                if (idxResultText >= 0) {
                                    tempData[idxGroupBy][idxResultText]['value'] += d.result_value
                                } else {
                                    tempData[idxGroupBy][d.group_by].push({
                                        name: d.result_text,
                                        value: d.result_value
                                    })
                                }
                            } else {
                                let groupBy = d.group_by
                                tempData.push({
                                    [groupBy]: [{
                                        name: d.result_text,
                                        value: d.result_value
                                    }]
                                })
                            }
                        })
                        tempData.forEach( tmpValue => {
                            let xAxisArray = []
                            let dataArray = []
                            let groupTotal = 0
                            tmpValue[Object.keys(tmpValue)[0]].forEach(d => {
                                xAxisArray.push(d.name)
                                dataArray.push(d.value)
                                groupTotal += d.value
                            })
                            data.push({
                                [Object.keys(tmpValue)[0]]: {
                                    'xAxis': xAxisArray,
                                    'data': dataArray,
                                    'groupTotal': groupTotal
                                }
                            })
                        })
                        data.sort((a, b) => {
                            let totalA = a[Object.keys(a)[0]].groupTotal
                            let totalB = b[Object.keys(b)[0]].groupTotal
                            if(totalA < totalB) return 1
                            if(totalA > totalB) return -1
                            return 0
                        })
                    }
                }
            } else {
                if(this.tableOptions.yearOnYear == 1){
                    if (this.reportData) {
                        let tempData = this.reportData
                        tempData.sort((a, b) => {
                            if (a.index < b.index) return -1
                            if (a.index > b.index) return 1
                            return 0
                        })
                         if (tempData.length > 10) {
                            let tempDataArray = []
                            let size = 10
                            for (let i = 0; i < tempData.length; i += size) {
                                tempDataArray.push(tempData.slice(i, i + size))
                            }
                            for (let i = 0; i < tempDataArray.length; i++) {
                                let firstRow = []
                                firstRow.push('') //temp
                                tempDataArray[i].forEach(d => {
                                    firstRow.push(d.result_text)
                                })
                                data[i] = [firstRow]
                                this.years.forEach(y => {
                                    let tempSingleData = []
                                    tempSingleData.push(y.indexValue)
                                    tempDataArray[i].forEach(d => {
                                        tempSingleData.push(d[y.indexValue])
                                    })
                                    data[i].push(tempSingleData)
                                })
                            }
                        } else {
                             let firstRow = []
                             firstRow.push('') //temp
                             tempData.forEach(d => {
                                 firstRow.push(d.result_text)
                             })
                             data[0] = [firstRow]
                             this.years.forEach(y => {
                                 let tempSingleData = []
                                 tempSingleData.push(y.indexValue)
                                 tempData.forEach(d => {
                                     tempSingleData.push(d[y.indexValue])
                                 })
                                 data[0].push(tempSingleData)
                             })
                        }
                    }
                } else {
                    if (this.reportData) {
                        let tempData = []
                        this.reportData.forEach(d => {
                            let idx = tempData.findIndex(el => el.name == d.result_text)
                            if (idx >= 0) {
                                tempData[idx]['value'] += d.result_value
                            } else {
                                tempData.push({
                                    name: d.result_text,
                                    value: d.result_value
                                })
                            }
                        })
                        if(tempData.length > 30){
                            let tempDataArray = []
                            let size = 30
                            for(let i = 0; i < tempData.length; i += size){
                                tempDataArray.push(tempData.slice(i,i + size))
                            }
                            for(let i = 0; i < tempDataArray.length; i++){
                                data.push({
                                    'xAxis': [],
                                    'data': []
                                })
                                tempDataArray[i].forEach(d => {
                                    data[i].xAxis.push(d.name)
                                    data[i].data.push(d.value)
                                })
                            }
                        } else {
                            data[0] = {
                                'xAxis': [],
                                'data': []
                            }
                            tempData.forEach(d => {
                                data[0].xAxis.push(d.name)
                                data[0].data.push(d.value)
                            })
                        }
                    }
                }
            }
            return data
        },
        yearOnYearHeaders () {
            let headers = [
                {
                    id: 1,
                    text: '#',
                    value: 'index',
                    class: 'py-0 pr-0 pl-1 font-md text-left width-1-pct',
                    searchable: false,
                    sortable: false
                },
                {
                    id: 2,
                    text: this.tableTitles.resultBy,
                    value: 'result_text',
                    class: 'py-0 pr-0 pl-1 font-md text-left',
                    searchable: false,
                    sortable: true
                },
            ]
            let n = 3
            for(let i = this.tableTitles.yearFrom; i <= this.tableTitles.yearTo; i++){
                headers.push({
                    id: n,
                    text: 'Y' + i.toString() + ' (' + this.tableTitles.monthFrom + '-' + this.tableTitles.monthTo + ')',
                    value: 'Y' + i.toString(),
                    class: 'py-0 pr-0 pl-1 font-md text-end',
                    searchable: false,
                    sortable: true
                })
                n++
            }
            return headers
        },
        yearOnYearTotals () {
            let totals = {}
            let endYear = this.tableTitles.yearTo
            let startYear = this.tableTitles.yearFrom
            while(startYear <= endYear){
                totals['Y' + startYear] = 0
                startYear = startYear + 1
            }
            return totals
        },
        years () {
            let array = []
            let endYear = this.tableTitles.yearTo
            let startYear = this.tableTitles.yearFrom
            while(startYear <= endYear){
                array.push({
                    indexName: 'item.Y' + startYear.toString(),
                    indexValue: 'Y' + startYear.toString()
                })
                startYear = startYear + 1
            }
            return array
        }
    },
    methods: {
        async beforeDownload({ html2pdf, options, pdfContent }) {
            if(this.tableOptions.groupBy != null || this.tableOptions.yearOnYear == 1) {
                document.getElementById('chart_container').classList.remove('overflow-y-auto')
                document.getElementById('chart_container').style.height = 'auto'
            } else {
                if(Array.isArray(this.reportBarData)){
                    document.getElementById('chart_container').classList.remove('overflow-y-auto')
                    document.getElementById('chart_container').style.height = 'auto'
                } else {
                    document.getElementById('app').style.setProperty('height', '750px')
                }
            }
            let canvasWidth =  document.getElementsByTagName('canvas')[0].offsetWidth
            let canvas = document.getElementsByTagName('canvas')
            canvas.forEach(c => {
                c.style.setProperty('width', '1050px')
            })
            let newOptions = options
            newOptions['margin'] = 5
            newOptions['pdf-content-width'] = null
            newOptions['html2canvas'] = { scale: 1, scrollY: 0 }
            newOptions['jsPDF'] = {
                format: 'a4',
                orientation: 'l'
            }
            await html2pdf().set(newOptions).from(pdfContent).save()
            canvas.forEach(c => {
                c.style.setProperty('width', canvasWidth)
            })
            if(this.tableOptions.groupBy != null || this.tableOptions.yearOnYear == 1) {
                document.getElementById('chart_container').classList.add('overflow-y-auto')
                document.getElementById('chart_container').style.setProperty('height', this.tabular.barChartHeight + 'px')
            } else {
                if(Array.isArray(this.reportBarData)){
                    document.getElementById('chart_container').classList.add('overflow-y-auto')
                    document.getElementById('chart_container').style.setProperty('height', this.tabular.barChartHeight + 'px')
                } else {
                    document.getElementById('app').style.removeProperty('height')
                }
            }
        },
        customSort( items ) {
            items.sort((a, b) => {
                if (a.group_position > b.group_position){
                    return -1
                } else if (a.group_position < b.group_position){
                    return 1
                } else {
                    if(a.result_value > b.result_value){
                        return -1
                    } else {
                        return 1
                    }
                }
            })
            if(this.tableOptions.groupBy != null) {
                let index = 1
                let lastGroupBy = null
                items.forEach((item,idx) => {
                    if(lastGroupBy == null && index == 1){
                        items[idx]['index'] = index
                        lastGroupBy = item['group_by']
                        index++
                    } else if(lastGroupBy != null && lastGroupBy == item['group_by']){
                        items[idx]['index'] = index
                        index++
                    } else if(lastGroupBy != null && lastGroupBy != item['group_by']){
                        index = 1
                        items[idx]['index'] = index
                        index++
                        lastGroupBy = item['group_by']
                    }
                })
            } else {
                items.forEach((item, idx) => {
                    items[idx]['index'] = idx + 1
                })
            }
            return items
        },
        formatDate,
        formatThisNumber(value,format) {
            return numberFormat(value,format)
        },
        getPercent,
        getReportBarOptions(data) {
            let text = null
            let barData = []
            let title = {}
            let series = []
            let legend = {}
            let dataSet = null
            let xAxisData = null
            let xAxisAxisLabelRotate = 0
            let colors = Please.make_scheme(
                {
                    h: 109,
                    s: 0.5238,
                    v: 0.4118
                },
                {
                    scheme_type: 'split',
                }
            )
            if(this.tableOptions.groupBy != null || this.tableOptions.yearOnYear == 1){
                if(this.tableOptions.yearOnYear == 1 && this.tableOptions.groupBy == null) {
                    barData = data
                    dataSet = {
                        source: barData
                    }
                    barData[0].forEach((d, idx) => {
                        if(idx > 0){
                            series.push({
                                name: d,
                                type: 'bar'
                            })
                        }
                    })
                    colors = [
                        '#064789',
                        '#427AA1',
                        '#7B3E19',
                        '#679436',
                        '#A5BE00',
                        '#5DD9C1',
                        '#ACFCD9',
                        '#B084CC',
                        '#665687',
                        '#190933'
                    ]
                    xAxisAxisLabelRotate = 30
                    legend = {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                        bottom: 20,
                        formatter: (value) => {
                            return value.slice(0,25)
                        },
                    }
                } else if(this.tableOptions.yearOnYear == 1 && this.tableOptions.groupBy != null){
                    barData = data.data
                    dataSet = {
                        source: barData
                    }
                    barData[0].forEach((d, idx) => {
                        if(idx > 0){
                            series.push({
                                name: d,
                                type: 'bar'
                            })
                        }
                    })
                    // this.years.forEach((y) => {
                    //     series.push({
                    //         name: y.indexValue,
                    //         type: 'bar'
                    //     })
                    // })
                    // colors = Please.make_scheme(
                    //     {
                    //         h: 109,
                    //         s: 0.5238,
                    //         v: 0.4118
                    //     },
                    //     {
                    //         scheme_type: 'split',
                    //     }
                    // )
                    colors = [
                        '#064789',
                        '#427AA1',
                        '#7B3E19',
                        '#679436',
                        '#A5BE00',
                        '#5DD9C1',
                        '#ACFCD9',
                        '#B084CC',
                        '#665687',
                        '#190933'
                    ]
                    xAxisAxisLabelRotate = 30
                    legend = {
                        type: 'scroll',
                        orient: 'vertical',
                        right: 10,
                        top: 20,
                        bottom: 20,
                        formatter: (value) => {
                            return value.slice(0,25)
                        },
                    }
                    title = {
                        text: data.groupTitle,
                        subtextStyle: {
                            color: '#000'
                        },
                        left: '150px',
                    }
                } else {
                    barData = data.data
                    title = {
                        text: data.groupTitle,
                        subtext: "$" + data.data.groupTotal.toLocaleString('en-Us', {style: 'decimal'}),
                        subtextStyle: {
                            color: '#000'
                        },
                        left: 'center'
                    }
                    series.push({
                        type: "bar",
                        data: barData.data
                    })
                    xAxisData = barData.xAxis
                    xAxisAxisLabelRotate = barData.data.length > 8 ? (barData.data.length < 20 ? 30 : 45 ) : 0
                }
            } else {
                text = null
                barData = data
                series.push({
                    type: "bar",
                    data: barData.data
                })
                xAxisData = barData.xAxis
                xAxisAxisLabelRotate = barData.data.length > 8 ? (barData.data.length < 20 ? 30 : 45 ) : 0
            }
            return {
                title: title,
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: 'none'
                    }
                },
                color: colors,
                dataset: dataSet,
                grid: {
                    left: '5%',
                    right: '5%',
                    top: '3%',
                    bottom: '3%',
                    containLabel: true
                },
                legend: legend,
                xAxis: {
                    name: this.tableTitles.resultBy,
                    type: 'category',
                    data: xAxisData,
                    axisLabel: {
                        textStyle: {
                            color: '#000'
                        },
                        formatter: (value) => {
                            return value.slice(0,25)
                        },
                        interval: 0,
                        rotate: xAxisAxisLabelRotate
                    },
                    axisTick: {
                        alignWithLabel: true
                    },
                    axisLine: {
                        show: false
                    },
                    z: 10
                },
                yAxis: {
                    name: this.tableTitles.value,
                    type: 'value',
                    nameGap: 75,
                    nameRotate: 90,
                    nameLocation: 'center',
                    axisLabel: {
                        formatter: (value) => {
                            let valuePerThousand = value/1000
                            valuePerThousand = valuePerThousand.toLocaleString('en-US', {style: 'decimal'})
                            return '$' + valuePerThousand + ' K'
                        },
                    }
                },
                series: series
            }
        },
        groupTotal (items) {
            let groupTotal = 0
            items.forEach(item => {
                groupTotal += item.result_value
            })
            return groupTotal
        },
        handleResize() {
            this.tabular.tableHeight = window.innerHeight - (200);
            this.tabular.barChartHeight = window.innerHeight - (200);
            this.tabular.multipleTableHeight = window.innerHeight - (240);
            // if(this.tableOptions.yearOnYear == 1 && typeof this.reportBarData == 'object'){
            //     let i = 0
            //     for(const [value] of Object.entries(this.groupedReportData)){
            //         i++
            //     }
            //     switch(this.tableOptions.filterBy){
            //         case 'top_5':
            //             if(i > 2) this.tabular.multipleTableHeight = (this.tabular.multipleTableHeight / 2.75 ) - 28
            //             break;
            //         case 'top_10':
            //             if(i > 2) this.tabular.multipleTableHeight = (this.tabular.multipleTableHeight / 1.5 ) - 28
            //             break;
            //         case 'top_25':
            //         default:
            //             if(i > 2) this.tabular.multipleTableHeight = (this.tabular.multipleTableHeight / 1.25 ) - 28
            //             break;
            //     }
            // }
        },
        openDetails (val, groupByVal) {
            let tab = null
            if(groupByVal != null){
                tab = window.open('/v1/marketing-report/detail?resultId=' + val + '&groupById=' + groupByVal + '&reportHash=' + this.reportHash,'_blank')
            } else {
                tab = window.open('/v1/marketing-report/detail?resultId=' + val + '&reportHash=' + this.reportHash,'_blank')
            }
            tab.name = 'marketingReportDetails_' + val
            this.openedTabs.push(tab.name)
        },
        openGroupDetails (groupByVal) {
            let tab = window.open('/v1/marketing-report/detail?groupById=' + groupByVal + '&reportHash=' + this.reportHash,'_blank')
            tab.name = 'marketingReportDetails_' + groupByVal
            this.openedTabs.push(tab.name)
        },
        openYearDetails (val, groupByVal, yearValue) {
            let tab = null
            yearValue = yearValue.replace('Y','')
            if(groupByVal != null){
                tab = window.open('/v1/marketing-report/detail?resultId=' + val + '&groupById=' + groupByVal + '&reportYear=' + yearValue + '&reportHash=' + this.reportHash,'_blank')
            } else {
                tab = window.open('/v1/marketing-report/detail?resultId=' + val + '&reportYear=' + yearValue + '&reportHash=' + this.reportHash,'_blank')
            }
            tab.name = 'marketingReportDetails_' + val
            this.openedTabs.push(tab.name)
        },
        openBarDetails (params) {
            // log(params)
        },
        barGroupTotal (items) {
            let total = 0
            items.forEach(i => {
                total += i.value
            })
            let dollarUSLocale = Intl.NumberFormat('en-US');
            return dollarUSLocale.format(total)
        },
        barYearTotal (items) {
            let total = 0
            for(const [idx, data] of Object.entries(items)){
                data.forEach(d => {
                    total += d.value
                })
            }
            let dollarUSLocale = Intl.NumberFormat('en-US');
            return dollarUSLocale.format(total)
        },
        printBarChart() {
            this.$refs.html2Pdf.generatePdf()
        },
        rendered (){
            this.echartRendering = false;
        },
        yearOnYearCustomSort (items, sortIndex, sortDesc) {
            items.sort((a, b) => {
                if (a.group_position > b.group_position){
                    return -1
                } else if (a.group_position < b.group_position){
                    return 1
                } else {
                    if(this.tableOptions.groupBy != null) {
                        if (a[sortIndex[1]] > b[sortIndex[1]]) {
                            // console.log(sortDesc[1])
                            if (sortDesc[1] == true) {
                                return -1
                            } else {
                                return 1
                            }
                        } else if(a[sortIndex[1]] < b[sortIndex[1]]) {
                            // console.log(sortDesc[1])
                            if (sortDesc[1] == true) {
                                return 1
                            } else {
                                return -1
                            }
                        } else {
                            return 0
                        }
                    } else {
                        if (a[sortIndex[0]] > b[sortIndex[0]]) {
                            console.log(sortDesc[0])
                            if (sortDesc[0] == true) {
                                return -1
                            } else {
                                return 1
                            }
                        } else if (a[sortIndex[0]] < b[sortIndex[0]]) {
                            console.log(sortDesc[0])
                            if (sortDesc[0] == true) {
                                return 1
                            } else {
                                return -1
                            }
                        } else {
                            return 0
                        }
                    }
                }
            })
            if(this.tableOptions.groupBy != null) {
                let index = 1
                let lastGroupBy = null
                items.forEach((item,idx) => {
                    if(lastGroupBy == null && index == 1){
                        items[idx]['index'] = index
                        lastGroupBy = item['group_by']
                        index++
                    } else if(lastGroupBy != null && lastGroupBy == item['group_by']){
                        items[idx]['index'] = index
                        index++
                    } else if(lastGroupBy != null && lastGroupBy != item['group_by']){
                        index = 1
                        items[idx]['index'] = index
                        index++
                        lastGroupBy = item['group_by']
                    }
                })
            } else {
                items.forEach((item, idx) => {
                    items[idx]['index'] = idx + 1
                })
            }
            let itemsPerPage = -1
            if(this.tableOptions.filterBy != null) {
                switch(this.tableOptions.filterBy){
                    case 'top_5':
                        itemsPerPage = 5
                        break
                    case 'top_10':
                        itemsPerPage = 10
                        break
                    case 'top_25':
                        itemsPerPage = 25
                        break
                }
                this.itemsPerPageYearOnYear = itemsPerPage
            } else {
                this.itemsPerPageYearOnYear = itemsPerPage
            }

            //reset totals
            this.years.forEach(y => {
                this.yearOnYearTotals[y.indexValue] = 0
            })
            this.tableTitles.total = 0

            //generate totals
            if(itemsPerPage != -1) {
                for (let i = 0; i < itemsPerPage; i++) {
                    this.years.forEach(y => {
                        this.yearOnYearTotals[y.indexValue] += items[i][y.indexValue]
                        this.tableTitles.total += items[i][y.indexValue]
                    })
                }
            } else {
                items.forEach(item => {
                    this.years.forEach(y => {
                        this.yearOnYearTotals[y.indexValue] += item[y.indexValue]
                        this.tableTitles.total += item[y.indexValue]
                    })
                })
            }

            let i = 0
            if(this.tableOptions.groupBy != null) i = 1
            this.tableTitles.sortDescending = sortDesc[i]
            this.tableTitles.sortColumn = sortIndex[i]

            return items
        },
        yearOnYearGroupTotal (items, year) {
            let groupTotal = 0
            items.forEach(item => {
                groupTotal += item[year.indexValue]
            })
            return groupTotal
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
    },
    mounted(){
        this.handleResize()
    },
    beforeUpdate() {
        this.handleResize()
        if(this.tableOptions.yearOnYear){
            this.yearOnYearSortBy = 'Y' + this.tableTitles.yearFrom.toString()
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style>
.border-top td {
    border-top: #3C6932 3px solid;
}
.border-top td:first-child {
    border-left: #3C6932 3px solid;
}
.border-top td:last-child {
    border-right: #3C6932 3px solid;
}
.border-bottom td {
    border-bottom: #3C6932 3px solid !important;
}
.border-bottom td:first-child {
    border-left: #3C6932 3px solid;
}
.border-bottom td:last-child {
    border-right: #3C6932 3px solid;
}
.border-left-and-right td:first-child {
    border-left: #3C6932 3px solid;
}
.border-left-and-right td:last-child {
    border-right: #3C6932 3px solid;
}
.border-top-and-bottom-and-left-and-right td {
    border-top: #3C6932 3px solid;
    border-bottom: #3C6932 3px solid !important;
}
.border-top-and-bottom-and-left-and-right td:first-child {
    border-left: #3C6932 3px solid;
}
.border-top-and-bottom-and-left-and-right td:last-child {
    border-right: #3C6932 3px solid;
}
.font-xs {
    font-size: 0.60rem;
}
.mt-5px {
    margin-top: 5px !important;
}
.mt-6px {
    margin-top: 6px !important;
}
.text-start {
    padding-left: 3px !important;
    padding-top: 3px !important;
    padding-right: 3px !important;
    vertical-align: middle !important;
}
.truncate {
    display: inline-block;
    width: 50px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.v-data-table td {
    padding: 0 3px;
    font-size: 0.8rem !important;
    line-height: 0.8rem;
}
.v-data-table-header th {
    white-space: nowrap;
}
.v-data-table td:first-child {
    width: 32px !important;
}
.echarts {
    width: var(--width);
    height: var(--height);
    padding-top: var(--padding-top);
}

.detail-link {
    cursor: pointer;
}
.detail-link:hover {
    color: #1D2433;
    font-weight: bold;
    text-decoration: underline;
}
</style>